import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./App.css";

const images = [
  'image1.jpeg',
  'image2.png',
  // Add the remaining images
  'image3.jpeg',
  'image4.jpeg',
  // 'image5.jpeg',
  'image6.jpeg',
  'image7.jpeg',
  'image8.jpeg',
  'image9.jpeg',
  'image10.jpeg',
  'image11.jpeg',
  'image12.jpeg',
  'image13.jpeg',
  'image14.jpeg',
  'image15.jpeg',
  'image16.jpeg',
  'image17.jpeg',
  'image18.jpeg',
  'image19.jpeg',
  'image20.jpeg',
  'image21.jpeg',
  'image22.jpeg',
  'image23.jpeg',
  'image24.jpeg',
  'image25.jpeg',
  'image26.jpeg',
  'image27.jpeg',
  'image28.jpeg',
  'image29.jpeg',
  'image30.jpeg',
  'image31.jpeg',
  'image32.jpeg',
  'image33.jpeg',
  'image34.jpeg',
  // 'image35.jpeg',
  // 'image36.jpeg',
  // 'image37.jpeg',
  // 'image38.jpeg',
  // 'image39.jpeg',
  // 'image40.jpeg',
  // 'image41.jpeg',
  // 'image42.jpeg',
  // 'image43.jpeg',
  // 'image44.jpeg',
  // 'image45.jpeg',
  // 'image46.jpeg',
  // 'image47.jpeg',
  // 'image48.jpeg',
  // 'image49.jpeg',
  // 'image50.jpeg'
];

function Home() {
  return (
    <div className="App-gallery">
      <header className="App-header-gallery">
        <h1 className="top-text font1">
          Here is our
          <br />
          <span className="font2"> Really </span>
          <span className="font2"> Cool </span>
          <span className="font2"> Gallery </span>
          <br />
        </h1>
        <h2 style={{ marginTop: "-10px", marginBottom: '40px' }} className="text-4 font1">
          Hope you enjoy
        </h2>
        <Link to="/">
          <button className="crazy-button">
            <span className="text">Return Home</span>
          </button>
        </Link>
      </header>
      
        <div className="photo-grid">
          {images.map((image, index) => (
            <div key={index} className="photo-grid-item">
              <LazyLoadImage
                src={`/${image}`}
                alt={`Gallery ${index + 1}`}
                effect="blur" // You can choose other effects like 'opacity' or 'black-and-white'
              />
            </div>
          ))}
        </div>
        <div className="footer-text font1 text-center">
        $STOOL coin is a meme coin with no intrinsic value or expectation of
          financial return. I, the website creator @bsbasebs, am not affiliated
          with the project in any way, just bought a bag and like the meme, it's
          too stupid not to run. Hmu if you want some changes on TG.  Also if you are happy and want to send me some stool - FVNSR6epRyEQgF5TroWuEMGD7bc9w6uunFY5YaAXfGfy
        </div>
    </div>
  );
}

export default Home;
