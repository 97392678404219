import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./App.css";

const expressions = ["🐈", "🐈", "🐈"];

function Home() {
  const [expression, setExpression] = useState(expressions[0]);
  const [clicked, setClicked] = useState(false);

  const changeExpression = () => {
    const randomExpression =
      expressions[Math.floor(Math.random() * expressions.length)];
    setExpression(randomExpression);
  };

  const handleClick = () => {
    setClicked(true);
    setTimeout(() => setClicked(false), 1000);
  };

  return (
    <div
      className="App"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src="/floating.png" // Update with the correct path to the image
        alt="Floating Cat with Stool"
        className="floating-image"
      />
      <header className="App-header">
        <h1 className="top-text font1">
          Welcome to
          <br />
          <span className="font2 text-main">
            {" "}
            <div>Stool Cat</div>
            <img
              src="/whiteout.png" // Update with the correct path to the image
              alt="whiteout"
              width="300px"
              height="300px"
              className="main-photo"
            />
          </span>
          <br />
        </h1>
        <h2 style={{ marginTop: "-150px" }} className="text-4 font2">
          Click the stool or you're a tool
        </h2>
      </header>
      <Link to="/gallery">
        <img
          src="/logo.png"
          alt="Stool Cat"
          width="300px"
          height="auto"
          className="in-and-out"
        />
      </Link>
      {/* <Link to="/gallery">
          <button
            className="crazy-button"
            onMouseEnter={changeExpression}
            onClick={handleClick}
          >
            <span className="emoji">{expression}</span>
            <span className="text">Gallery</span>
          </button>
        </Link> */}
      <div className="both">
        <div className="social-buttons">
          <a
            href="https://x.com/StoolCatSol"
            target="_blank"
            rel="noopener noreferrer"
            className="social-button"
          >
            <img src="/twitter-logo.png" alt="Twitter" />
          </a>
          <a
            href="https://t.me/+Gavr4Kn-zVc0ZDcx"
            target="_blank"
            rel="noopener noreferrer"
            className="social-button"
          >
            <img src="/telegram-logo.png" alt="Telegram" />
          </a>
          <a
            href="https://pump.fun/88cTXocr1bRogThZ5vi4ezjqQeZwQxKhoDBTWkbDpump"
            target="_blank"
            rel="noopener noreferrer"
            className="social-button"
          >
            <img src="/pump-fun-logo.png" alt="Pump.fun" />
          </a>
        </div>
        <div className="footer-text">
          $STOOL coin is a meme coin with no intrinsic value or expectation of
          financial return. I, the website creator @bsbasebs, am not affiliated
          with the project in any way, just bought a bag and like the meme, it's
          too stupid not to run. Hmu if you want some changes on TG.  Also if you are happy and want to send me some stool - FVNSR6epRyEQgF5TroWuEMGD7bc9w6uunFY5YaAXfGfy
        </div>
      </div>
    </div>
  );
}

export default Home;
